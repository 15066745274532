<template>
  <List
    title="metarole user"
    resource="bundleMetaroleUsage"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    data-provider="$authDataProvider"
    :search="true"
    search-placeholder="Search for a user by name"
    :request-params="{bundleId: bundleId, metaroleId: metaroleId}"
    :pageSize="20"
    :filter-classes="{container: 'w-2/3 items-end', quickFilters: 'w-1/2 pr-6', searchContainer: 'flex-1'}"
  >
    <template v-slot:quickfilter="{applyFilter}">
      <div class="form-col">
        <label class="label">customer</label>
        <Dropdown
            :options="customerFilterOptions"
            :value="customerFilter"
            @input="v => { if (v !== customerFilter) { applyFilter(mapFilterToQuery(v)); } }"
            placeholder="Search for a customer by name"
            object-mode
        />
      </div>
    </template>
  </List>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import List from "@/components/auth/list/List";
  import UserField from '@/components/auth/list/fields/UserField';
  import StatusField from "@/components/auth/list/fields/StatusField";
  import Dropdown from "@/components/ui/Dropdown";

  export default {
    name: 'MetaroleUsersList',
    components: {
      List,
      Dropdown,
    },
    mixins: [ModalNavigation, ConfirmationMixin, NotifyMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      bundleId: function () {
        return this.$route.params.bundleId;
      },
      metaroleId: function () {
        return this.$route.params.metaroleId;
      },
    },
    data() {
      return {
        customerFilter: null,
        customerFilterOptions: [],
        fields: [
          {
            name: UserField,
            title: 'user',
            sortField: 'fullName',
            switch: {
              getName: rowData => rowData.fullName
            }
          },
          {
            name: StatusField,
            sortField: 'status',
            title: 'Status',
            switch: {
              source: 'status'
            }
          },
          {
            name: 'customerName',
            title: 'customer',
          },
        ]
      }
    },
    methods: {
      mapFilterToQuery(value) {
        this.customerFilter = value;

        if (!value) { return {}; }

        return {
          customerId: value?.key,
        };
      },
    },
    mounted() {
      this.$authDataProvider.getOne('bundles', {id: this.bundleId})
          .then(({ metaroles }) => {
            const metarole = metaroles.find(({ id }) => id === this.metaroleId);
            if (metarole) {
              this.setActiveModalTitle(`${metarole.name} metarole users`);
              this.setActiveModalBreadcrumbName(`${metarole.name} metarole`);
            }
          })
          .catch(error => this.notifyError(error.message));

      this.$authDataProvider.getList('customers', { size: 999 })
          .then(({content}) => {
            this.customerFilterOptions = content.map(({ customerId, name }) => ({ key: customerId, value: name }));
          }).catch((err) => this.notifyError(err.message));
    },
  }
</script>

<style scoped>

</style>
